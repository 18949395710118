import React from "react";
import { Card, Col, Nav, NavItem, NavLink, Row } from "reactstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaSkype,
  FaLinkedinIn,
  FaWhatsapp,
  FaMapMarkedAlt,
} from "react-icons/fa";

import { AiFillWechat, AiFillMail } from "react-icons/ai";
import { IoMdCall } from "react-icons/io";
import CardTitle from "reactstrap/lib/CardTitle";
import Container from "reactstrap/lib/Container";
import CardText from "reactstrap/lib/CardText";
import ArjivLogo from "../logo.png";

const Footer = () => (
  <>
    <Container fluid className="contact-section">
      <Row md={2} xs={1} sm={1} className="pt-5" fluid>
        <Col>
          <Card className="footer-fcard">
            <CardTitle className="footer-card">
              <img src={ArjivLogo} height="25px" width="25px" /> {"  "}
              Mumbai Office
            </CardTitle>
            <CardText className="footer-text">
              CC-7085, Bharat Diamond Bourse, BKC, Bandra East,
              <br />
              Mumbai, Maharashtra 400051
              <p className="pt-3">
                <a href="tel:+912223630651" className="number px-2">
                  <IoMdCall />
                  +91 22 23630651
                </a>
              </p>
              <p>
                <a className="number" href="mailto:info@arjiv.com">
                  <AiFillMail /> info@arjiv.com
                </a>
                <br />
                <a
                  className="number"
                  href="https://www.google.co.in/maps/place/Arjiv+Export/@19.0649636,72.8614507,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7c8e8a1f99d19:0x9058e81c33e92c9e!8m2!3d19.0649585!4d72.8636394"
                  target="_blank"
                >
                  <FaMapMarkedAlt /> Google Map
                </a>
              </p>
            </CardText>
          </Card>
        </Col>
        <Col>
          <Card className="footer-fcard">
            <CardTitle className="footer-card">
              <img src={ArjivLogo} height="25px" width="25px" /> {"  "}
              Surat Manufacturing Unit
            </CardTitle>
            <CardText className="footer-text">
              CS NO 3074, Survey NO 412-1 Block NO. 1C, Opp Reshamvala Reyon,
              <br />
              Vastadevadi Road Katargam, Surat, Gujarat, 395004
              <p className="pt-3">
                <a href="tel:+912612533383" className="number  pr-2">
                  <IoMdCall />
                  &nbsp;+91 261 2533383
                </a>
              </p>
              <p>
                <a className="number" href="mailto:surat@arjiv.com">
                  <AiFillMail /> surat@arjiv.com
                </a>
                <br />
                <a
                  className="number"
                  href="https://www.google.com/maps/place/Arjiv+Exports/@21.214597,72.8343257,17z/data=!3m1!4b1!4m5!3m4!1s0x3be04ff77461f8c1:0x976e1d8c296af3d1!8m2!3d21.214592!4d72.8365144"
                  target="_blank"
                >
                  <FaMapMarkedAlt /> Google Map
                </a>
              </p>
            </CardText>
          </Card>
        </Col>
        <Col md="12">
          <Card className="footer-fcard">
            <CardTitle className="footer-card">
              <img src={ArjivLogo} height="25px" width="25px" /> {"  "}
              Social Media Links
            </CardTitle>
            <Nav className="footer-text">
              <NavItem>
                <NavLink
                  href="https://www.facebook.com/arjivexports"
                  className="sicon"
                  target="_blank"
                >
                  <FaFacebookF color="#3b5998" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.instagram.com/arjivexportsnyc"
                  className="sicon"
                  target="_blank"
                >
                  <FaInstagram color="#3f729b" />
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  href="https://www.youtube.com/channel/UC4-RsQOjr6hrwjqGp6H6oTA?"
                  className="sicon"
                  target="_blank"
                >
                  <FaYoutube color="#FF0000" />
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink href="skype:live:.cid.5f5ec33ba48feb87?chat" className="sicon">
                  <FaSkype color="#00aff0" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://api.whatsapp.com/send?phone=+917405793333&text=I%27m%20Interested%20in%20buying%20Diamonds%20from%20you"
                  className="sicon"
                  target="_blank"
                >
                  <FaWhatsapp color="#075e54" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://u.wechat.com/IEaKFeEc_-6I4QnjDR5qCQo"
                  className="sicon"
                  target="_blank"
                >
                  <AiFillWechat color="#9de60b" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.linkedin.com/in/arjiv-exports-a38bbb1b6"
                  className="sicon"
                  target="_blank"
                >
                  <FaLinkedinIn color="#0e76a8" />
                </NavLink>
              </NavItem>
            </Nav>{" "}
          </Card>
        </Col>
      </Row>
    </Container>
    <div className="footer-row copyright">
      <p>
        COPYRIGHT 2020{" "}
        <span>
          <a href="http://www.arjivexports.in">ARJIV EXPORTS PVT. LTD.</a>
        </span>
        <span> ALL RIGHTS RESERVED.</span>
      </p>
    </div>
  </>
);

export default Footer;

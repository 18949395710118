import React from "react";
import { Container, Row } from "reactstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "./bodysection.css";

function Policies() {
  return (
    <div className="main-content">
      <Container className="main-content-con text-center pt-4">
        <h2 className="text-center pt-3">Policy – Supply Chain</h2>
        <Row className="col-md-12 mt-4">
          <p className="pt-3">
            his Policy demonstrates Arjiv Exports is committed and its
            expectations for its product suppliers regarding actions to address
            Conflict Minerals. Arjiv Exports expects it's suppliers to have in
            place policies and due diligence measures that will enable us to
            reasonably assure that products and components supplied to us
            containing conflict minerals are DRC conflict free. We being a
            responsible company, Arjiv Exports supports the goal of the
            Dodd-Frank Act of preventing armed groups in the Democratic Republic
            of the Congo and adjoining countries from benefitting from the
            sourcing of Conflict Minerals from that region. Arjiv Exports is
            committed to working with its suppliers to educate them on these
            matters and concerning steps they can take to obtain increased
            transparency regarding the origin of minerals contained in the
            products they manufacture and sell to Arjiv Exports. Arjiv Exports
            reserves the right to request additional documentation from its
            suppliers regarding the origin of any Conflict Minerals included in
            any products sold to Arjiv Exports . Suppliers who do not reasonably
            comply with this Policy shall be reviewed by Arjiv Exports for
            future business.
          </p>
        </Row>
        <h2 className="text-center pt-3">Policy – Sourcing</h2>
        <Row className="col-md-12 mt-4">
          <p className="pt-3">
            Arjiv Exports is committed to ensuring that our supply chain is free
            of any metal which was procured for the support or benefit of armed
            and anti social conflict groups or involving serious abuses of human
            rights and non compliant with OECD Guidelines. Arjiv Exports clearly
            criticizes such activity and will reject any material which we
            believe was obtained involving serious human rights violations or
            which benefitted or supported armed rebels or terrorist groups
            through illegal finance or other activities. The company shall carry
            out risk assessment for its supply chain and shall not enter into
            any business relationship or if may require then shall
            suspend/discontinue the engagement with any such supplier involved
            in dealing with Conflict-Affected and High-Risk areas, Any form of
            Human Right Violation; Torture, Cruel, In-Human and Degrading
            Treatment; Forced/Compulsory Labour; Child Labour; Abuses such as
            widespread Sexual Violence; War Crimes; other serious violations of
            International Humanitarian Law, Crime against Humanity; Genocide
            and/or To Bribe or To be Bribed. We strictly condemn and prohibit
            any Direct/Indirect support to public/private security forces which
            illegally Control, Tax or Extort money from Mining Sites,
            Transportation Routes and Upstream Sectors. The Company shall carry
            out due diligence to assess risks related to procurement from the
            Conflict-affected and high-risk areas – CAHRAs and shall always
            source from compliant miners/refiners/traders We shall always set
            reasonable efforts to source Conflict Minerals from smelters and
            refiners validated as being DRC Conflict Free, and require their
            direct and indirect suppliers to do the same; We strive to work
            supportively with our customers and supply chain partners in
            implementing conflict minerals compliance programs.
          </p>
        </Row>
        <h2 className="text-center pt-3">Policy – Due Diligence</h2>
        <Row className="col-md-12 mt-4">
          <p className="pt-3">
            The company shall always undertake to ensure that the extraction and
            trade of diamond support peace and development, not conflict. Arjiv
            Exports remains committed to enhance its Supply Chain Due Diligence
            program through internal review and external assessments. We have
            zero tolerance policy for the supplier violating OECD due diligence
            guideline and we shall immediately stop commercial relationship if
            any of our business associates found non-compliant or High-Risk
            during our internal/external risk assessment. Currently Arjiv
            Exports procure it’s precious metals from RJC-CoC and CoP compliant
            miners/refiners/traders. However, we have established a strong due
            diligence process and we shall review it as an when we observed
            significant risk or upon receipt of any grievance or complaint but
            in normal course, we shall carry our due diligence process on annual
            basis.
          </p>
        </Row>
        <h2 className="text-center pt-3">Grievance Mechanism</h2>
        <Row className="col-md-12 mt-4">
          <p>
            Our employees, suppliers and other parties can report concerns and
            alleged violations of supply chain/sourcing/due diligence policy as
            follows: Email: surat@arjiv.com Reports can be made
            anonymously and will be kept confidential to the fullest extent
            practicable and allowed by law. We will not take any retaliatory
            action against our employees, suppliers, or other parties who make a
            report in good faith. Our suppliers are encouraged to contact
            surat@arjiv.com if they wish to seek guidance on the
            application of this Policy.
          </p>
        </Row>
        <div className="bg-gray text-center py-3">
          <p className="">Date: 01/01/2020 | India</p>
        </div>
      </Container>
    </div>
  );
}

export default Policies;

import React from "react";
import {
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import emailjs from "emailjs-com";

// Toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "./bodysection.css";

import { Button, Form, FormGroup, Label, Input } from "reactstrap";

function Contactus() {
  // const successmessage =
  const MsgForm = () => {
    //  contact us email send function
    const sendMail = (e) => {
      e.preventDefault();

      emailjs
        .sendForm(
          "service_nbvn0ud",
          "template_7nxny7m",
          e.target,
          "user_nt3qKY3PjR7SlU0aE8UUt"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      e.target.reset();
    };

    return (
      <Form onSubmit={sendMail}>
        <ToastContainer />
        <Row>
          <Col>
            <FormGroup className="text-left">
              <Label for="Name">Name *</Label>
              <Input
                type="text"
                name="name"
                id="Name"
                placeholder="Full Name"
                required
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="text-left">
              <Label for="Email">Email *</Label>
              <Input
                type="email"
                name="email"
                id="Email"
                placeholder="your@email.com"
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup className="text-left">
          <Label for="text">Subject *</Label>
          <Input
            type="text"
            name="subject"
            id="subject"
            placeholder="Subject"
            required
          />
        </FormGroup>
        <FormGroup className="text-left">
          <Label for="textarea">Your Message</Label>
          <Input
            type="textarea"
            name="yourmessage"
            id="yourmessage"
            placeholder="Write Your Message"
          />
        </FormGroup>

        <Button
          left
          type="submit"
          onClick={() => {
            toast("Message Send", { type: "success" });
          }}
        >
          Send Your Message
        </Button>
        {/* <Input type="submit" value="Submit" /> */}
      </Form>
    );
  };

  return (
    <div className="main-content">
      <Container className="py-5 main-content">
        <Row xs="1" sm="1" md="2">
          <Col>
            <CardTitle className="title">CONTACT FORM STYLE</CardTitle>
            <MsgForm />
            <div style={{ paddingTop: "10px" }}>
              <iframe
                width="100%"
                height="400"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Vasta%20Devdi%20Rd,%20Tunki,%20Katargam,%20Surat,%20Gujarat%20395004+(arjiv%20Exports)&amp;t=k&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </Col>
          <Col>
            <CardTitle className="title">CONTACT INFO STYLE</CardTitle>
            <CardBody>
              <CardSubtitle className="title2">WHERE TO REACH US</CardSubtitle>
              <CardText className="contacttext">
                You can reach us at the following <br /> Address: Bharat Diamond
                Bourse, Mumbai - 400051.
              </CardText>
              <CardSubtitle className="title2">EMAIL US @</CardSubtitle>
              <CardText className="contacttext">
                Email your issues and suggestion for the following
                <br /> Email Addresses: info@arjiv.com
              </CardText>
            </CardBody>
            <CardBody>
              <CardSubtitle className="title2">NEED TO CALL US?</CardSubtitle>
              <CardText className="contacttext">
                From Monday to Friday,10:00 AM - 8:00 PM,
                <br /> Call Us at: +91 2223630651
              </CardText>
              <CardSubtitle className="title2">
                WE HAVE GREAT SUPPORT
              </CardSubtitle>
              <CardText className="contacttext">
                We provide the best Quality of products to you.We are always
                here to help our lovely customers.We ship our products anywhere
                with more secure. We provide the best Quality of products to
                you.
              </CardText>
            </CardBody>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contactus;

import React, { Component } from "react";
import Modal from "react-awesome-modal";
import "./popup.css";
import OrderForm2 from "./customorder";

import { AiOutlineCloseSquare } from "react-icons/ai";
import "bootstrap/dist/css/bootstrap.min.css";

class OrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  openModal() {
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }

  render() {
    return (
      <section>
        <div id="arjiv-creation" className="arjiv-creation1">
          <p style={{ margin: "0px" }}>Arjiv</p>
          <p style={{ margin: "0px" }}> Creation</p>
        </div>
        <input
          type="button"
          value="Customize Your Diamonds"
          onClick={() => this.openModal()}
          className="btn btn-outline-light bg-image-text"
        />
        <Modal
          className="modelcheck"
          visible={this.state.visible}
          width="80%"
          effect="fadeInDown"
          onClickAway={() => this.closeModal()}
        >
          <div className="model-content">
            <div className="modelheader">
              <span>
                Send Your Demand Name
                <AiOutlineCloseSquare
                  style={{
                    float: "right",
                    fontSize: "25px",
                    marginTop: "15px",
                  }}
                  onClick={() => this.closeModal()}
                />
              </span>
            </div>

            <div className="modelbody">
              <OrderForm2 props={this.closeModal} />
            </div>
          </div>
        </Modal>
      </section>
    );
  }
}

export default OrderForm;

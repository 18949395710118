import React from "react";

import "./page1/style1.css";
import diamondlogo from "./page1/diamond.png";




function Journey() {
  return (
    <div>
      <main className="main-content">
        <div className="content-wrapper">
          <div className="journery-holder pb-3">
            <div className="container py-5">
              <div className="row m0 journeryTitle mb-5">
                <h3>
                  THE
                  <br />
                  <span>JOURNEY</span>
                </h3>
                <h6>WELCOME TO THE WORLD OF ARJIV.</h6>
                <br />
                <br />
                <p>
                  Founded in 1985, ARJIV EXPORTS has always believed in
                  Innovation, Excellence and Proficiency. Since then,we have
                  been striving to meet the highest standards of manufacturing
                  each diamond.
                </p>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="main-timeline9">
                    <div className="timeline">
                      <div className="timeline-content">
                        <div className="circle">
                          <span>
                            <img src={diamondlogo} alt="" />
                          </span>
                        </div>
                        <div className="content">
                          <span className="year">1980's - 1990's</span>
                          <h4 className="title">Single Cut Diamonds</h4>
                          <p className="description">
                            A single cut on the other hand is a simple form of
                            cutting a round diamond with only 17 or 18 facets.
                            If you compare a Single Cut Diamond (usually small
                            Carat Weights of 1.2mm or less), to a Full Cut
                            Diamond (58 facets), you’d quickly see the
                            difference. Small high quality single cut diamonds
                            are highly prized especially in the watch industry,
                            and because there are fewer but larger facets, they
                            give off a majestic dance of light and colour, that
                            is often lost in a sea of white light with the full
                            cut stones
                          </p>
                          <div className="icon">
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="timeline">
                      <div className="timeline-content">
                        <div className="circle">
                          <span>
                            <img src={diamondlogo} alt="" />
                          </span>
                        </div>
                        <div className="content">
                          <span className="year">2000's</span>
                          <h4 className="title">Brilliant Cut Diamonds</h4>
                          <p className="description">
                            A brilliant cut is simple a diamond or other
                            gemstone cut in a particular form with numerous
                            facets so as to have exceptional brilliance. The
                            shape resembles of a cone and provides maximised
                            light return through the top of a diamond. Round
                            Brilliant Cuts are moreover used in modern
                            jewellery.
                          </p>
                          <p className="description">
                            A brilliant cut is simply a diamond or any other
                            gemstone cut in a particular form with numerous
                            facets so to ensure exceptional brilliance.
                          </p>
                          <div className="icon">
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="timeline tm-8">
                      <div className="timeline-content">
                        <div className="circle">
                          <span>
                            <img src={diamondlogo} alt="" />
                          </span>
                        </div>
                        <div className="content">
                          <span className="year">2008</span>
                          <h4 className="title">Rose Cut Diamonds</h4>
                          <p className="description">
                            The rose cut is one of the oldest cutting style for
                            diamonds mordern jewellery designers are creating
                            edges(Contemparory jewellery with an interesting,
                            old world vibe by adding rose cut. The ancient art
                            of faceting a stone to look like an opening nose,
                            originated in India in the 16th century. The result
                            is a stone that is still coveted today by high-end
                            designers and consumers alike. This brilliant
                            time-honored cut allows light to reflect within 24
                            domed facets(Size 0.01 to 20.00 cts),
                            Colour(o-z.natural fancy colour).
                          </p>
                          <div className="icon">
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="timeline tm-12">
                      <div className="timeline-content">
                        <div className="circle">
                          <span>
                            <img src={diamondlogo} alt="" />
                          </span>
                        </div>
                        <div className="content">
                          <span className="year">2012</span>
                          <h4 className="title">Fancy Coloured Diamonds</h4>
                          <p className="description">
                            Natural, Intense fancy coloured diamonds are as rare
                            as they exquisite. Arjiv exports carries a variety
                            of fancy colours in a range of shapes, sizes,
                            colours and clarity. They are 100% natural and
                            un-treated with heat or chemicals so not to
                            compromise their value or quality.
                          </p>
                          <div className="icon">
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="timeline mt-0 pb-5 tm-15">
                      <div className="timeline-content">
                        <div className="circle">
                          <span>
                            <img src={diamondlogo} alt="" />
                          </span>
                        </div>
                        <div className="content">
                          <span className="year">2015 - Present</span>
                          <h4 className="title">Step Cut Diamonds</h4>
                          <p className="description">
                            Stones whose outlines are either square or
                            rectangular and whose facets are rectilinear and
                            arranged parallel to the griddle known as
                            step-or-step cut stones. These stones often have
                            their corners truncated creating an embrald cut with
                            octagonal outline.
                          </p>
                          <div className="icon">
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Journey;

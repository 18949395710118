import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

// css Link
import "bootstrap/dist/css/bootstrap.min.css";
import "../discoverpage/discoverpage.css";
import "react-image-lightbox/style.css";

import Image01 from "../discoverpage/Images/whatwedo/what  (1).jpg";
import Image02 from "../discoverpage/Images/whatwedo/what  (2).jpg";
import Image03 from "../discoverpage/Images/whatwedo/what  (3).jpg";

function WhatWeDo() {
  class DiscoverCarousel extends Component {
    render() {
      return (
        <Carousel
          autoPlay={true}
          swipeable={true}
          showArrows={true}
          showThumbs={false}
          useKeyboardArrows={true}
          infiniteLoop={true}
          interval={4000} // 4000
          showStatus={false}
          transitionTime={1500} // 1500
          emulateTouch={true}
        >
          <div>
            <img src={Image01} alt="OldCut" />
          </div>
          <div>
            <img src={Image02} alt="OldCut" />
          </div>
          <div>
            <img src={Image03} alt="OldCut" />
          </div>
        </Carousel>
      );
    }
  }
  return (
    <div>
      <DiscoverCarousel />
    </div>
  );
}

export default WhatWeDo;

import React from "react";
import { Container, Row } from "reactstrap";
import RJCLogo from "./Image/RJC-logo.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "./bodysection.css";

function Rjcpolicy() {
  return (
    <div className="main-content">
      <Container className="main-content-con text-center pt-4">
        <h2 className="text-center pt-3">RJC Compliance Policy</h2>
        <Row className="col-md-12 mt-4">
          <p className="pt-3">
            Arjiv Exports is a member of Responsible Jewellery Council – RJC and
            committed for ethical business practices and compliance with Code of
            Practices in all its business activities. The management of Arjiv
            Exports shall implement responsible ethical, social and
            environmental practices throughout the diamond supply chain, from
            mine to retail. We being a responsible entity of diamond supply
            chain believe in consumer confidence in our trade and request our
            business partners to follow same. As a part of best endeavors and
            responsible business entity, we anticipate co-operation of our
            business partners in adopting the standard and ensuring that the
            entire diamond supply chain is compliant with respect to business,
            social and environmental responsibilities. We strongly encourage all
            our business partners to follow and implement the various
            requirements of the RJC code of practice and other prevailing trade
            standards. More information about the Compliance Program is
            available on{" "}
            <a href="http://www.responsiblejewellery.com">
              www.responsiblejewellery.com
            </a>{" "}
            For Arjiv Exports
          </p>
        </Row>
        <Row>
          <div
            className="zoom"
            style={{
              height: "500px",
              width: "500px",
            }}
          >
            <a href="https://www.responsiblejewellery.com/member/arjiv-exports/">
              <img src={RJCLogo} alt="" />
              <p className="bg-secondary p-2">Responsible Jewellery Council</p>
            </a>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default Rjcpolicy;

import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import BG from "./Image/bg-3.jpg";

import "bootstrap/dist/css/bootstrap.min.css";
// import "./popup.css";

// Image Import

import Cust1 from "./Image/cust-1.jpg";
import Cust2 from "./Image/cust-2.jpg";
import Cust3 from "./Image/cust-3.jpg";
import Cust4 from "./Image/cust-4.jpg";
import { CardBody, CardText, CardTitle, Container } from "reactstrap";
import OrderForm from "./popup";

function Customization() {
  return (
    <div className="main-content">
      <div className="first-banner">
        <div className="bgimage-handler">
          <img src={BG} alt="" className="bgimage" style={{ width: "100%" }} />
          <OrderForm className="" />
          {/* <Button
            className="btn  bg-image-text theme-btn mt-4"
            onClick={OrderForm()}
          >
            customiz Your Diamond
          </Button> */}
        </div>
        <div className="bgtext-handler">
          <p className="bgtext">
            ARJIV EXPORTS manufactures Diamonds of all Shapes, Size, Color,
            Clarity and Measurements, perfectly suited for your Jewellery.
          </p>
        </div>
      </div>
      <div className="py-2 mx-5 px-3">
        <div className="imagesection">
          <Container fluid>
            <Row className="" xs="1" sm="1" md="2">
              <Col className="cust p-2">
                <img src={Cust1} alt="" className="" />
              </Col>
              <Col className="cust p-2">
                <Card body className="py-5">
                  <CardBody className="custtext">
                    <CardTitle className="custtitle">CUSTOMER IDEAS</CardTitle>
                    <CardText className="custtextbody">
                      At ARJIV, we recognize your IDEAS as well as you do. So
                      whether you begin with just an imagination or a
                      full-ﬂedged design concept, we will work actively with you
                      to help provide you perfect set of Diamonds for your
                      jewellery.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="" xs="1" sm="1" md="2">
              <Col className="cust p-2">
                <Card body className="py-5">
                  <CardBody className="custtext">
                    <CardTitle className="custtitle">
                      CUSTOMER PROCUREMENT
                    </CardTitle>
                    <CardText className="custtextbody">
                      Once we have a custom design, we will CUT all the diamonds
                      necessary as per the measurements. Our trained craftsmen
                      will match every diamond’s gauge to the design
                      requirements, ensuring the correct cut and quality of
                      diamonds.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col className="cust p-2">
                <img src={Cust4} alt="" className="" />
              </Col>
            </Row>
            <Row className="" xs="1" sm="1" md="2">
              <Col className="cust p-2">
                <img src={Cust3} alt="" className="" />
              </Col>
              <Col className="cust p-2">
                <Card body className="py-5">
                  <CardBody className="custtext">
                    <CardTitle className="custtitle">
                      MANUFACTURING EXPERTISE
                    </CardTitle>
                    <CardText className="custtextbody">
                      Our in-house team of highly-trained Craftsmen will then
                      use the design as a blueprint to craft your customized
                      diamonds. By combining old-world charm with new-age
                      techniques, the Diamonds will be provided for the
                      beautiful piece that it is meant to be.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="" xs="1" sm="1" md="2">
              <Col className="cust p-2">
                <Card body className="py-5">
                  <CardBody className="custtext">
                    <CardTitle className="custtitle">
                      BESPOKE CREATIONS
                    </CardTitle>
                    <CardText className="custtextbody">
                      ARJIV EXPORTS guarantees customer satisfaction, trust and
                      door to door delivery across the Globe.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col className="cust p-2">
                <img src={Cust2} alt="" className="" />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default Customization;

import React, { useState } from "react";
import { Button, Col, Container, FormText, Row } from "reactstrap";
import axios from "../../Utility/axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, FormGroup, Label, Input } from "reactstrap";

import "./bodysection.css";

// mobile number

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

// Toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";

// Imagepicker Icon Import

import Img1 from "./Shapeicon/1.png";
import Img2 from "./Shapeicon/2.png";
import Img3 from "./Shapeicon/3.png";
import Img4 from "./Shapeicon/4.png";
import Img5 from "./Shapeicon/5.png";
import Img6 from "./Shapeicon/6.png";
import Img7 from "./Shapeicon/7.png";
import Img8 from "./Shapeicon/8.png";
import Img9 from "./Shapeicon/9.png";
import Img10 from "./Shapeicon/10.png";
import Img11 from "./Shapeicon/11.png";
import Img12 from "./Shapeicon/12.png";
import Img13 from "./Shapeicon/13.png";
import Img14 from "./Shapeicon/14.png";
import Img15 from "./Shapeicon/15.png";
import Img16 from "./Shapeicon/16.png";
import Img17 from "./Shapeicon/17.png";
import Img18 from "./Shapeicon/18.png";
import Img19 from "./Shapeicon/19.png";
import Img20 from "./Shapeicon/20.png";
import Img21 from "./Shapeicon/21.png";
import Img22 from "./Shapeicon/22.png";
import Img23 from "./Shapeicon/23.png";

const OrderForm2 = (props) => {
  const [file, setFile] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [value, setValue] = useState({
    ShapeList: [],
    Doc: "",
    FromCarat: "",
    ToCarat: "",
    FromColor: "",
    ToColor: "",
    FromClarity: "",
    ToClarity: "",
    FromLength: "",
    ToLength: "",
    FromWidth: "",
    ToWidth: "",
    FromHeight: "",
    ToHeight: "",
    MobileNumber: "",
    FromFluo: "",
    ToFluo: "",
    EmailId: "",
    Message: "",
    FromRatio: "",
    ToRatio: "",
    FromRange: "",
    ToRange: "",
    TimeLimit: "",
  });

  // send emailjs function
  const sendMail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_nbvn0ud",
        "customization",
        e.target,
        "user_nt3qKY3PjR7SlU0aE8UUt"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  const GetSelectedValue = (e) => {
    const control = e.target;
    const name = control.name;
    const tvalue = control.value;
    setValue({ ...value, [name]: tvalue });
  };

  const FileChange = (e) => {
    const f = e.target.files[0];
    setFile(f);
  };

  const SetShapeList = (e) => {
    const imageName = e.target.name;
    let list = [...value.ShapeList];
    if (e.target.checked) list = [...list, imageName];
    else list = list.filter((img) => img !== imageName);
    setValue({ ...value, ShapeList: list });
  };

  const Submit = () => {
    const obj = { ...value, MobileNumber: phoneNumber };
    // const json = JSON.stringify(obj);
    // const blob = new Blob([json], {
    //   type: 'application/json',
    // });
    const formData = new FormData();
    if (file) formData.append("attachment", file);
    // data.append('document', blob);
    for (const [key, val] of Object.entries(value)) {
      formData.append(key, val);
    }

    console.log("Sending axios request");

    // axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
    // axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios
      .post("/test", formData, {
        header: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error({ error });
      });
    // axios({
    //   method: 'get',
    //   url: '/test',
    // })
    //   .then(response => {
    //     console.log(response);
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });
  };

  // const Close = () => {
  //   toast("Close", {
  //     type: "warning",
  //   });
  // };

  return (
    <>
      <ToastContainer />
      <Form enctype="multipart/form-data" onSubmit={sendMail}>
        <Container className="px-4 py-3">
          <Row xs={1} sm={1} md={2} className="formgroup">
            <Col>
              <FormGroup>
                <Label for="color">Select Shape :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="shape1"
                      id="Select"
                      onChange={GetSelectedValue}
                      required
                    >
                      {shapes.map((color, index) => (
                        <option value={color[0]} key={index}>
                          {color[1]}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="shape2"
                      id="Select"
                      onChange={GetSelectedValue}
                      required
                    >
                      {shapes.map((color, index) => (
                        <option value={color[0]} key={index}>
                          {color[1]}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>

          {/* <Row xs={1} sm={1} md={1} className="formgroup">
            <Col className="Shapeholder">
              <FormGroup className="shapegroup">
                <Label className="selectshape">Select Shape :</Label>
                <Row>
                  <ul>
                    {images.map((img, index) => {
                      const id = `cb${index + 1}`;
                      // console.log("Image ::: ", images[index]);
                      console.log(value.ShapeList);
                      return (
                        <li key={index}>
                          <input
                            type="checkbox"
                            id={id}
                            name={images[index]}
                            className="shapeitem"
                            onChange={SetShapeList}
                            value={images[index]}
                          />
                          <label for={id}>
                            <img src={img[1]} alt="" />
                          </label>
                        </li>
                      );
                    })}
                    <li>
                      <label for="cb24">
                        <span>Other</span>
                      </label>
                      <input
                        type="checkbox"
                        id="cb24"
                        className="shapeitem-other"
                      />
                    </li>
                  </ul>
                </Row>
              </FormGroup>
            </Col>
          </Row> */}
          <Row xs={1} sm={1} md={2} className="formgroup">
            <Col>
              <FormGroup>
                <Label py={0} for="file">
                  Upload Doc :
                </Label>
                <Col md={6}>
                  <Input
                    type="file"
                    name="file"
                    id="fileselection"
                    onChange={FileChange}
                  />
                </Col>
              </FormGroup>
            </Col>{" "}
            <Col>
              <FormGroup>
                <Label for="carat">Carat * :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="FromCarat"
                      id="carat"
                      placeholder="From Carat*"
                      required
                      onChange={GetSelectedValue}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="ToCarat"
                      id="carat"
                      placeholder="To Carat*"
                      required
                      onChange={GetSelectedValue}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row xs={1} sm={1} md={2} className="formgroup">
            <Col>
              <FormGroup>
                <Label for="color">Color * :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="FromColor"
                      id="Select"
                      onChange={GetSelectedValue}
                      required
                    >
                      {colorsform.map((color, index) => (
                        <option value={color[0]} key={index}>
                          {color[1]}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="ToColor"
                      id="Select"
                      onChange={GetSelectedValue}
                      required
                    >
                      {colorsto.map((color, index) => (
                        <option value={color[0]} key={index}>
                          {color[1]}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="clarity">Clarity* :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="FromClarity"
                      id="Select"
                      onChange={GetSelectedValue}
                      required
                    >
                      {claritiesfrom.map((clarity, index) => (
                        <option value={clarity[0]} key={index}>
                          {clarity[1]}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="ToClarity"
                      id="Select"
                      onChange={GetSelectedValue}
                      required
                    >
                      {claritiesto.map((clarity, index) => (
                        <option value={clarity[0]} key={index}>
                          {clarity[1]}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row xs={1} sm={1} md={2} className="formgroup">
            <Col>
              <FormGroup>
                <Label for="Email">Length :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="FromLength"
                      id="length"
                      placeholder="From Length"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="ToLength"
                      id="length"
                      placeholder="To Length"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="width">Width :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="FromWidth"
                      id="width"
                      placeholder="From Width"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="ToWidth"
                      id="width"
                      placeholder="To Width"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row xs={1} sm={1} md={2} className="formgroup">
            <Col>
              <FormGroup>
                <Label for="height">Height :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="FromHeight"
                      id="height"
                      placeholder="From Height"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="ToHeight"
                      id="height"
                      placeholder="To Height"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="contact">Mobile No* :</Label>
                <Row>
                  <Col md={8} center>
                    <PhoneInput
                      defaultCountry="US"
                      country="US"
                      placeholder="Enter Mobile Number"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      name="MobileNumber"
                      // onChange={GetSelectedValue}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row xs={1} sm={1} md={2} className="formgroup">
            <Col>
              <FormGroup>
                <Label for="Fluorescence">Fluorescence :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="FromFluo"
                      id="fluorescence"
                      placeholder="Select From Fluorescence*"
                      onChange={GetSelectedValue}
                    >
                      {flsfrom.map((fl, index) => (
                        <option value={fl[0]} key={index}>
                          {fl[1]}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="ToFluo"
                      id="fluorescence"
                      placeholder="Select To Fluorescence*"
                      onChange={GetSelectedValue}
                    >
                      {flsto.map((fl, index) => (
                        <option value={fl[0]} key={index}>
                          {fl[1]}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="EmailID">Email ID* :</Label>
                <Row>
                  <Col md={8}>
                    <Input
                      type="email"
                      name="Email"
                      id="email"
                      placeholder="Email ID*"
                      onChange={GetSelectedValue}
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row xs={1} sm={1} md={1} className="formgroup">
            <Col md={8}>
              <FormGroup>
                <Label for="Message">Message :</Label>
                <Input
                  type="textarea"
                  name="Message"
                  id="message"
                  placeholder="Write Your Message"
                  onChange={GetSelectedValue}
                />
                <FormText size={100}>We Provide Only Loose Diamonds *</FormText>
              </FormGroup>
            </Col>
          </Row>
          <Row xs={1} sm={1} md={1} className="formgroup">
            <FormGroup center>
              <legend className="text-center">Advance Search</legend>
            </FormGroup>
          </Row>
          <Row xs={1} sm={1} md={2} className="formgroup">
            <Col>
              <FormGroup>
                <Label for="Ratio">Ratio :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="FromRatio"
                      id="ratio"
                      placeholder="From Ratio"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="ToRatio"
                      id="ratio"
                      placeholder="To Ratio"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="range">Budget Range :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="FromRange"
                      id="range"
                      placeholder="From Range"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="ToRange"
                      id="range"
                      placeholder="To Range"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row xs={1} sm={1} md={1} className="formgroup">
            <Col md={8}>
              <FormGroup>
                <Label for="timelimit">Time Limit :</Label>
                <Input
                  type="number"
                  name="TimeLimit"
                  id="timelimit"
                  placeholder="In How Many Days You Need"
                  onChange={GetSelectedValue}
                />
              </FormGroup>
            </Col>
          </Row>
        </Container>
        <div className="modelfooter">
          <Row inline Right style={{ float: "right" }}>
            <Col>
              <Button onClick={Submit} type="submit">
                Submit
              </Button>
              {/* <Button >Reset</Button> */}
              {/* <Button>Close</Button> */}
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default OrderForm2;

const images = [
  ["ROUND", Img1],
  ["PEAR", Img2],
  ["Img3", Img3],
  ["Img4", Img4],
  ["Img5", Img5],
  ["Img6", Img6],
  ["Img7", Img7],
  ["Img8", Img8],
  ["Img9", Img9],
  ["Img10", Img10],
  ["Img11", Img11],
  ["Img12", Img12],
  ["Img13", Img13],
  ["Img14", Img14],
  ["Img15", Img15],
  ["Img16", Img16],
  ["Img17", Img17],
  ["Img18", Img18],
  ["Img19", Img19],
  ["Img20", Img20],
  ["Img21", Img21],
  ["Img22", Img22],
  ["Img23", Img23],
];

const colorsform = [
  ["", "Select Form Color *"],
  ["D", "D"],
  ["E", "E"],
  ["F", "F"],
  ["G", "G"],
  ["H", "H"],
  ["I", "I"],
  ["J", "J"],
  ["K", "K"],
  ["L", "L"],
  ["M", "M"],
  ["FANCY", "FANCY"],
  ["LB", "LB"],
  ["P", "P"],
  ["L-FANCY", "L-FANCY"],
  ["I-FANCY", "I-FANCY"],
  ["N", "N"],
  ["O-P", "O-P"],
  ["Y-Z", "Y-Z"],
  ["U-V", "U-V"],
  ["S-T", "S-T"],
  ["W-X", "W-X"],
  ["D-FANCY", "D-FANCY"],
  ["V-FANCY", "V-FANCY"],
];

const colorsto = [
  ["", "Select To Color *"],
  ["D", "D"],
  ["E", "E"],
  ["F", "F"],
  ["G", "G"],
  ["H", "H"],
  ["I", "I"],
  ["J", "J"],
  ["K", "K"],
  ["L", "L"],
  ["M", "M"],
  ["FANCY", "FANCY"],
  ["LB", "LB"],
  ["P", "P"],
  ["L-FANCY", "L-FANCY"],
  ["I-FANCY", "I-FANCY"],
  ["N", "N"],
  ["O-P", "O-P"],
  ["Y-Z", "Y-Z"],
  ["U-V", "U-V"],
  ["S-T", "S-T"],
  ["W-X", "W-X"],
  ["D-FANCY", "D-FANCY"],
  ["V-FANCY", "V-FANCY"],
];

const claritiesfrom = [
  ["", "Select Form Clarity *"],
  ["IF", "IF"],
  ["VVS1", "VVS1"],
  ["VVS2", "VVS2"],
  ["VS1", "VS1"],
  ["VS2", "VS2"],
  ["SI1", "SI1"],
  ["SI2", "SI2"],
  ["SI3", "SI3"],
  ["I1", "I1"],
  ["I2", "I2"],
  ["I3", "I3"],
  ["I4", "I4"],
  ["FL", "FL"],
];

const claritiesto = [
  ["", "Select To Clarity *"],
  ["IF", "IF"],
  ["VVS1", "VVS1"],
  ["VVS2", "VVS2"],
  ["VS1", "VS1"],
  ["VS2", "VS2"],
  ["SI1", "SI1"],
  ["SI2", "SI2"],
  ["SI3", "SI3"],
  ["I1", "I1"],
  ["I2", "I2"],
  ["I3", "I3"],
  ["I4", "I4"],
  ["FL", "FL"],
];

const flsfrom = [
  ["", "Select From Fluorescen"],
  ["NONE", "NONE"],
  ["FAINT", "FAINT"],
  ["MEDIUM", "MEDIUM"],
  ["STRONG", "STRONG"],
  ["VERY STG", "VERY STG"],
];
const flsto = [
  ["", "Select To Fluorescen"],
  ["NONE", "NONE"],
  ["FAINT", "FAINT"],
  ["MEDIUM", "MEDIUM"],
  ["STRONG", "STRONG"],
  ["VERY STG", "VERY STG"],
];

const shapes = [
  ["", "Select Shape"],
  ["Round", "Round"],
  ["Pear", "Pear"],
  ["Oval", "Oval"],
  ["Heart", "Heart"],
  ["Marquise", "Marquise"],
  ["Princess", "Princess"],
  ["Emerald-4Step", "Emerald-4Step"],
  ["Triangle", "Triangle"],
  ["Cushion", "Cushion"],
  ["Emerald-5Step", "Emerald-5Step"],
  ["Bugget", "Bugget"],
  ["Bullet", "Bullet"],
];

import React from "react";
import Footer from "./Components/footer";
import HeaderPart from "./Components/header";
// import Particles from "react-particles-js";
import "./App.css";
import { TinyButton as BackTop } from "react-scroll-up-button";

const App = () => {
  return (
    <div>
      <BackTop />
      <HeaderPart />
      <Footer />
    </div>
  );
};

export default App;
